/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';
import { putItems } from '../../redux/components/reducer.action';
import { initializeReactGA } from '../../middleware/googleAnalytics';
import { viewContent, fetchContent } from '../../middleware/api';
import PostHeightIframe from './dynamicIframeHeight';
import { getValueFromSession, getEditStatus } from '../../middleware/utils';
import { FlipcardComponent } from './flipCard/view';
import { getLibraryId } from '../formComponent/utils';
import TabComponent from './tabs';
import AccordionComponent from './accordion/accordion';
import ButtonComponent from './button';
import ClickAndRevealComponent from './clickAndReveal';
import HotspotViewComponent from './hotspot/hotspotView';
import ImageSlider from './imageSlider';
import DragAndDropComponent from './draganddrop';
import MCQComponent from './multipleChoice';
import HandleErrorComponent from '../../errorHandler';
import { CustomFontsType } from '../editor/editorUtilityFunctions/lintEditorType';
import {
  getAdminDashboardFontFamilyList,
  getCustomHeaderFontDetails,
  handleEmbedLINT,
} from '../interactiveManagement/utils';
import { CONSTANTS } from '../editor/editorUtilityFunctions/constants';
import '../../components/editor/editor.scss';
import { SelectedElementDetails } from '../interactiveManagement';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import InteractiveLoader from '../loader/interactiveLoader';

const getInteractiveViewComponent = (
  parameters: any,
  customisations: any,
  type: string,
  id: string,
  isLmsView: boolean,
) => {
  switch (type) {
    case 'Accordion':
      return (
        <AccordionComponent
          parameters={parameters}
          customisations={customisations}
          isLMSView={isLmsView}
        />
      );
    case 'Tabs':
      return <TabComponent parameters={parameters} customisations={customisations} />;
    case 'Button':
      return <ButtonComponent parameters={parameters} customisations={customisations} />;
    case 'ClickAndRevealInteractions':
      return <ClickAndRevealComponent parameters={parameters} customisations={customisations} />;
    case 'imageslider':
      return <ImageSlider parameters={parameters} customisations={customisations} />;
    case 'Hotspot':
      return <HotspotViewComponent parameters={parameters} customisations={customisations} />;
    case 'DragandDrop':
      return <DragAndDropComponent parameters={parameters} customisations={customisations} />;
    case 'multiplechoice':
      return <MCQComponent parameters={parameters} customisations={customisations} />;
    case 'flipcard':
      return (
        <FlipcardComponent
          parameters={parameters}
          customisations={customisations}
          id={id}
          type='view'
        />
      );
  }
};

const mapState = ({ component }: any) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations,
});

const mapDispatch = (dispatch: Function) => ({
  PutItems: (component: {}) => dispatch(putItems(component)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & any;

const InteractiveView = (props: Props) => {
  const { title, parameters, customisations } = props;
  const { id } = props.match.params;
  const path = props.location.pathname.toLowerCase();
  const isLMSView =
    !path.toLowerCase().includes('new') && !path.toLowerCase().includes('contentview');
  const applyMargin = !isLMSView ? 'margin container-fluid pt-3' : '';
  const [editStatus, setEditStatus] = useState(false);
  const [componentType, setComponentType] = useState('');
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [headerConfig, setHeaderConfig] = useState({});
  const [activeLINT, setActiveLINT] = useState(true);

  useEffect(() => {
    const { id, type, user } = props.match.params;
    const { PutItems } = props;
    if (!isLMSView) {
      initializeReactGA(path);
    } else {
      PostHeightIframe();
    }
    const fetchData = async () => {
      const userDetails = getValueFromSession('user_id');
      try {
        const response = isLMSView ? await viewContent(id) : await fetchContent(id, userDetails);
        const isInvalidLMSResponse =
          isLMSView &&
          (response?.info ||
            type.toLowerCase() !== response?.type?.toLowerCase() ||
            response?.active === false);

        if (isInvalidLMSResponse || !response) {
          props.history.push('/204');
          return;
        }
        setActiveLINT(response.active);
        const isValidResponse =
          !response.message && response.library_id === getLibraryId(response.type);

        if (isValidResponse) {
          if (response.config) {
            const config = JSON.parse(response.config);
            const editStatus = getEditStatus(response.private, response.global, config);
            setEditStatus(editStatus);
          }
          setComponentType(response.type);
          PutItems({
            title: response.title,
            parameters:
              response.type === 'Button' ? response.parameters : JSON.parse(response.parameters),
            customisations: response.customisations,
          });
          setLoading(false);
        } else {
          props.history.push('/401');
        }

        let configData;
        let fontFamilyConfigList = [];
        if (!isLMSView) {
          configData = JSON.parse(response.config ?? null);
          fontFamilyConfigList = configData.configsList?.customFonts?.fonts ?? [];
        } else {
          configData = JSON.parse(response.customisations.fontsList ?? null);
          fontFamilyConfigList = configData ?? [];
        }

        if (props?.history?.location?.state) {
          const { fontFamilyList } = props.history.location.state;
          if (fontFamilyConfigList.length === 0) {
            fontFamilyConfigList = fontFamilyList?.fonts ?? [];
          }
        }

        let customFontFaceList: string[] = [];
        let fontApiUrl = CONSTANTS.LOREE_GOOGLE_FONT_API_URL;

        fontFamilyConfigList.forEach((font: CustomFontsType) => {
          if (
            Object.prototype.hasOwnProperty.call(font, 'url') &&
            font.url &&
            !Object.hasOwn(font, 'customFont')
          ) {
            const api = font.url.split('=');
            fontApiUrl += `&family=${api[1]}`;
          }
          if (Object.hasOwn(font, 'customFont')) {
            customFontFaceList.push(font.customFont as string);
          }
        });

        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'stylesheet');
        linkTag.setAttribute('href', fontApiUrl);
        linkTag.setAttribute('id', 'customFontsLink');
        linkTag.setAttribute('class', 'customFontsLink');

        const headElement = document.head;

        const existingCustomFontsLink = headElement.getElementsByClassName('customFontsLink')[0];
        existingCustomFontsLink?.remove();
        const existingCustomFonts = headElement.getElementsByClassName('customFonts')[0];
        existingCustomFonts?.remove();

        headElement?.appendChild(linkTag);

        if (customFontFaceList.length > 0) {
          const styleTag = document.createElement('style');
          styleTag.setAttribute('class', 'customFonts');
          customFontFaceList.forEach((customFontFace) => {
            styleTag.append(customFontFace);
          });
          headElement?.appendChild(styleTag);
        }

        const fontFamilyLists: Record<string, {}> = getAdminDashboardFontFamilyList(
          configData?.configsList,
        );
        const customHeaderStyleLists: Record<string, {}> = getCustomHeaderFontDetails(
          configData?.configsList,
        );

        if (!isLMSView) {
          setHeaderConfig({
            user_id: id,
            myinteractivecreate: props.location.state.config?.myinteractivecreate,
            globalinteractivecreate: props.location.state.config?.globalinteractivecreate,
            myinteractive: props.location.state.config?.myinteractive,
            globalinteractive: props.location.state.config?.globalinteractive,
            sharedinteractive: props.location.state.config?.sharedinteractive,
            myinteractiveedit: props.location.state.config?.myinteractiveedit,
            globalinteractiveedit: props.location.state.config?.globalinteractiveedit,
            sharedinteractiveedit: props.location.state.config?.sharedinteractiveedit,
            fontFamilyList: fontFamilyLists?.fonts ?? [],
            customHeaderStyleList: customHeaderStyleLists?.customHeader ?? [],
            customLinkStyleList: configData?.configsList?.customLinkStyle?.customLink ?? [],
          });
        } else {
          setHeaderConfig({
            user_id: id,
            fontFamilyList: fontFamilyLists?.fonts ?? [],
            customHeaderStyleList: customHeaderStyleLists?.customHeader ?? [],
            customLinkStyleList: configData?.configsList?.customLinkStyle?.customLink ?? [],
          });
        }
      } catch (error) {
        console.error('Error while fetching interactive content', error);
        setLoading(false);
        setIsError(true);
      }
    };
    fetchData();
  }, [isLMSView]);

  const handleEmbed = () => {
    const CurrentElementDetails: SelectedElementDetails = {
      id: props.match.params.id,
      user_id: props.match.params.user,
      title: title,
      library_type: props.match.params.type,
    };
    handleEmbedLINT(CurrentElementDetails);
  };

  const handleBackPage = () => {
    window.history.back();
  };

  return (
    <>
      {props.location.state !== undefined || isLMSView ? (
        <>
          {!isLMSView && loading && <InteractiveLoader loaderType={''} />}
          <div className={applyMargin} id='editInteractive'>
            {!loading && !isLMSView && !isError && (
              <Row className='d-flex justify-content-between mb-3'>
                <Col className='col-10 d-flex' style={{ wordBreak: 'break-word', gap: 8 }}>
                  <span
                    className='d-flex'
                    style={{ color: '#585858', cursor: 'pointer', gap: '5px', marginTop: '2px' }}
                    onClick={handleBackPage}
                  >
                    <BackIcon />
                    Back
                  </span>
                  <h2
                    className='pt-1 d-flex align-items-center'
                    style={{ color: '#112299', fontSize: '18px', fontWeight: 400 }}
                  >
                    {' '}
                    {title}
                  </h2>
                </Col>
                <Col className='col-2'>
                  <div className='d-flex justify-content-end ' style={{ gap: '20px' }}>
                    {editStatus && (
                      <Link
                        style={{ color: 'black' }}
                        className='float-end pt-1'
                        to={{
                          pathname: `/element/edit/${componentType}/${id}/${getValueFromSession(
                            'org_id',
                          )}/${getValueFromSession('loreeVersion')}`,
                          state: {
                            user:
                              props.location.state !== undefined ? props.location.state.user : '',
                            user_id:
                              props.location.state !== undefined
                                ? props.location.state.user_id
                                : '',
                            config: headerConfig,
                          },
                        }}
                      >
                        Edit
                      </Link>
                    )}
                    <button
                      className='btn btn-primary btn-sm embed-btn'
                      disabled={!activeLINT}
                      onClick={() => handleEmbed()}
                    >
                      Embed
                    </button>
                  </div>
                </Col>
              </Row>
            )}
            {!loading && !isError && (
              <>
                {getInteractiveViewComponent(
                  parameters,
                  customisations,
                  componentType,
                  id,
                  isLMSView,
                )}
              </>
            )}
            {!loading && isError && <HandleErrorComponent />}
          </div>
        </>
      ) : (
        <Redirect to='/401' />
      )}
    </>
  );
};

export default connector(InteractiveView);
